import './App.css';
import { useState, useEffect, useCallback } from 'react'
import {useDropzone} from 'react-dropzone'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { library } from '@fortawesome/fontawesome-svg-core'
//import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
//import { fas } from '@fortawesome/free-solid-svg-icons'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas,far)

const InformationBox = () => {
  return (
    <div className="wrapper-info-box">
      <div className="info-box">
        <div className="brand assently-logo"></div>
        <div className="brand scrive-logo"></div>
        <div className="brand verified-logo"></div>
        <div className="brand docusign-logo"></div>
        {/*<div className="brand admincontrol-logo"></div>
        <div className="brand verified-logo"></div>
        <div className="brand signicat-logo"></div>*/}
      </div>
    </div>
    ); 
}

const BankIDLogo = () => {
  return (
    <>
       <img src={"./BankID_logo.png"} height="35"/>
    </>); 
}

const BoxYes = () => {
  return (
    <>
       <span class="checkmark success"><FontAwesomeIcon icon="fa-solid fa-check" /></span>
    </>); 
}

// <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
const BoxNo = () => {
  return (
    <>
       <span class="checkmark fail"><FontAwesomeIcon icon="fa-solid fa-xmark" /></span> 
    </>); 
}

const WarningText = ({warning}) => {
  return (
    <>
      <div class="warning-text">
        <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" /> {warning}
      </div>
    </>); 
}

const Signature = ({signature}) => {
  return (
    <>
    <div className="signature">
      <table className="signature_table">
        <tr>
          <td>Fullständigt namn:</td>
          <td>{signature.name}</td>
        </tr>
        <tr>
          <td>BankID:</td>
          <td>{(signature.bankID ? (<BoxYes />) : (<BoxNo />) )} </td>
        </tr>
        {(signature.idNumber ? (
        <tr>
          <td>Personnummer:</td>
          <td>{signature.idNumber} </td>
        </tr>
        ) : "")}
        {(signature.ip ? (
        <tr>
          <td>IP/User Agent:</td>
          <td>{signature.ip}</td>
        </tr>
        ) : "")}
        <tr>
          <td>Tid för signering:</td>
          <td>{signature.time}</td>
        </tr>
        {(signature.emailAddress ? (
        <tr>
          <td>Epostadress:</td>
          <td>{signature.emailAddress}</td>
        </tr>
        ) : "")}
      </table>
    </div>
    </>
    );
}
const Document = ({doc}) => {
  return (
    (typeof doc.success != "undefined" ? (
    <div className={"document_card " +( (doc.service?.length > 0 ) ? doc.service.toLowerCase() : "") }>
      <h2>{doc.fileName}</h2>
      <hr/>
      <h2>{(typeof doc.service === "undefined" ? doc.service : "")} </h2>
      {(doc.success) ? (<div className="success">Validering lyckades</div>) : (<div className="fail">Validering misslyckades!</div>)}
{      /* doc.warnings_texts?.map((warning,i) => (<div class="fail">warning</div>) */ }
      {doc.warning_texts?.map( (warning,i) => (<WarningText warning={warning}/>))}
      <br/>
      {(doc.signatures?.length > 0) ? (doc.signatures.map((signature,i) => (<div className='signature_block'><div className='title'>Signatur #{i+1}</div><Signature key={(i+1)+"_signature"} signature={signature}/></div>))) : (<div className='signature_block'>Kunde inte hitta giltig signatur.</div>) }
    </div>
    ) : (
  <div className="document_card loading">
  <h1 className="loadingtext"></h1>
  <hr/>
  <h2 className="loadingtext"> </h2>
  <div className="signatures loadingtext"> </div>
</div>
)
    )
    
    );

}

function App() {
	const API_URL = 'https://test.valid8.se/upload';
  const [dropMessage,setDropMessage] = useState("Dra och släpp ett signerat PDF-dokument för att validera det");
  const [disable,setDisable] = useState(false);
  const [hide,setHide] = useState(true);
  const [verifiedDocument,setVerifiedDocument] = useState([]);

  useEffect( () => {
    
  }, []);
  const onDragEnter = () => {
    console.log("dragging!");
    setDropMessage("Släpp ett signerat PDF-dokument här för att validera det!");
    setHide(false);
    
  }
  const onDragLeave = () => {
    setDropMessage("Släpp ett signerat PDF-dokument här för att validera det!");
    setHide(true);
  }
  const onDrop = useCallback((acceptedFiles) => {
    setDisable(true);
    setHide(true);
    setDropMessage("Dokumentet blir validerat. Ha tålamod!");
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      let json = {};
        let d = verifiedDocument;
        json['fileName'] = file.name;
        d.unshift(json);
        setVerifiedDocument(d);
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result
        console.log(binaryStr);
        var data = new FormData();
        
        //data.append('file', binaryStr)
        data.append('file', new Blob([binaryStr], {type:"application/pdf"}));
        fetch(API_URL, {
          method: 'POST',
          body: data
        }).then( (data) => data.json() ).then( (jsonData) => {
          for(let key of Object.keys(jsonData)){
            json[key] = jsonData[key];
          }
        }).catch(()=>{console.log("error");}).finally(function(){
          setDisable(false);
          setDropMessage("Dra och släpp ett signerat PDF-dokument för att validera det");
        });
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  const {getRootProps,open, getInputProps} = useDropzone({onDrop, onDragEnter, onDragLeave, disabled: disable, noClick:true});
  
  return (
    <>
    <div className="header">valid8  {/*<FontAwesomeIcon icon="fa-solid fa-file-signature" />*/}
    </div>
    <div className={"upload" + ((disable === true) ? " disable" : "") + (verifiedDocument?.length > 0 ?  "" : " initial") } >
    <div {...getRootProps()} className={"dropzone " + ((disable === true) ? " disable" : "") }>
          <input {...getInputProps()} />
          <div className={"inner" + ((hide === true) ? " hidden " : "" )}><h1>{dropMessage}</h1></div>     
          <div className="droparea">Dra och släpp ett signerat PDF-dokument här</div>
          <div><button onClick={open}><FontAwesomeIcon icon="fa-regular fa-file-lines" /> Välj dokument</button></div>
      </div>
      <div className="loading">
      
      <div className="text">Dokumentet valideras...</div>
      <div className="waves">
        <span className="wave"></span>
        <span className="wave"></span>
        <span className="wave"></span>
      </div>
      </div>
    </div>
    <InformationBox/>

    <div className={"card_wrapper" + ((hide !== true) ? " blur " : "" )}>
    {
      verifiedDocument?.length > 0 ? (verifiedDocument.map((document,i) => 
      (<Document key={(i)+"_document"} doc={document}/>))
      ) : "" 
    }
    </div>
{/*    <InformationBox/> */ } 
    </>
  )
	

}

export default App;
